
  <template>
    <div style="height: 100vh;" class="" v-if="!showProgress">
    <div  style="height: 100%;" v-if="currentStep" class="onboarding-popup">
      <!-- <div class="progress-bar" :style="{ '--step': currentStepIndex + 1 }"></div> -->
      <div v-if="currentStep && currentStep.image"  class="" style="display: flex;align-items: end;">
        <img v-if="currentStep && currentStep.image" style="height: 80px;margin-right: 40px;" src="../assets/appeq.png" />
        <img v-if="currentStep && currentStep.image" style="height: 250px;margin-right: -30px;" src="../assets/morePerson.png" />
      
      </div>
      <div style="height: 45px;" class=""v-else>

      </div>
      <h1 style="word-spacing: 10px;letter-spacing: 5px;text-transform: uppercase !important;margin-bottom: 0px !important;">{{ currentStep.title }}</h1>
      <p style="font-size: 18px;margin-bottom: 35px !important;margin-top: 10px !important;">{{ currentStep.content }}</p>
      <div v-if="currentStep && currentStep.displayImgs" class="" style="display: flex;align-items: center;margin-top: 30px;">
        <!-- <img v-for="img of currentStep.displayImgs" :src="('../assets/'+img)" alt=""> -->
        <img
          v-tooltip="currentStep.hoverContent[idx]"
        style="margin-left: 40px;height: 120px;box-shadow: 5px 5px 10px 0px rgba(230.3234375, 54.576640625000024, 54.576640625000024, 0.5);border-style: none;border-radius: 50%;" v-for="(img,idx) of currentStep.displayImgs" :src="`/img/onboarding${idx+1}.png`" alt="">
        <!-- <img style="margin-left: 20px;height: 120px;" v-for="(img,idx) of currentStep.displayImgs" :src="(`/img/onboarding${idx+1}.png`" alt=""> -->

      </div>
      <!-- <p class="appeqfooter">{{ currentStep.footer }}</p> -->
      <div class="navigation-buttons">
        <!-- <button class="prevBtn" v-if="hasPrev" @click="prevStep">Previous</button> -->
        <div class="" ></div>
        <button v-if="hasNext" @click="nextStep">Next</button>
        <button v-else @click="closeOnboarding">Start Trial</button>
      </div>
    </div>
  </div>
  <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;" class="" v-else>
    <h2 style="margin-top: 20px;margin-bottom: 20px;">Please Wait</h2>
    <img src="../assets/setup.svg" style="height: 300px;"/>
    <h3>Setting Up Your Demo Account</h3>
    <span>This usually takes 2-3 minutes</span>
  </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import { JWT } from "node-jsonwebtoken";
  import onboardingConfig from "./onboarding.json"
  import Server from "../components/API/Server"


  export default {
    name: 'OnboardingPopup',
    // data(){
    //     return{
    //         currentStepIndex:0,
    //         // currentStep:onboardingConfig.steps[0]

    //     }
    // },
    methods:{
     
    },
    setup() {
      const currentStepIndex = ref(0);
      const steps = ref(onboardingConfig.steps);
      let showProgress=ref(false)
      const getParamsByKey=function(){
            const queryString = window.location.search.slice(1); // Remove the leading '?'
            const params = new URLSearchParams(queryString);

            const filteredParams = {};
            params.forEach((key, val) => {
                console.log(key, val)
                filteredParams[val] = key;
            });

            return filteredParams;
      }
      // const location=window.location.href
      const email=getParamsByKey()['email']
      localStorage.setItem("__email__id",email)
      const currentStep = computed(() => steps.value[currentStepIndex.value]);
  
      const hasNext = computed(() => currentStepIndex.value < steps.value.length - 1);
      const hasPrev = computed(() => currentStepIndex.value > 0);
  
      function nextStep() {
        if (hasNext.value) currentStepIndex.value++;
      }
  
      function prevStep() {
        if (hasPrev.value) currentStepIndex.value--;
      }
      // function getImgUrl(pic) {
      //   return require('../assets/'+pic)
      // }

      async function openDash(number) {
            // const userInfo = (await ApiServer.getUsersData(localStorage.getItem("__email__id"))).data
            // this.org_id = userInfo.data.org_id
            var METABASE_SITE_URL = "https://app.appeq.ai/bi";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            const jwt = new JWT(METABASE_SECRET_KEY);
            let email = localStorage.getItem('__email__id')

            const token = await jwt.sign({
                resource: { dashboard: number },
                params: {},
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";
            // this.metabaseDashboard=iframeUrl
            return iframeUrl
            // feeds.sendActionToTop("__appeq_open_executive_dashboard", iframeUrl);

      }
  
      async function closeOnboarding() {
        console.log("Onboarding finished.");
        showProgress.value=true
        const data=await Server.cloneDataToOrg()
        setTimeout(() => {
          showProgress.value=false
        }, 2000);
        const dashboardUrl=await openDash(273)
        console.log(dashboardUrl)
        window.parent.postMessage({action:"__appeq_clone_complete",value:localStorage.getItem("__email__id"),url:dashboardUrl},"*")
        // You might want to do something here like close the popup or redirect the user
      }
  
      return { currentStep, currentStepIndex,hasNext, hasPrev, nextStep, prevStep, closeOnboarding,showProgress };
    }
  };
  </script>
  
  <style lang="scss" scoped>
.onboarding-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: auto;
  text-align: center;
}

.onboarding-popup img {
  height: 200px;
  margin-bottom: 20px;
}

h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.appeqfooter {
  color: #999;
  font-size: 14px;
  margin-bottom: 30px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4e5fff;
  color: white;
  font-weight: bold;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
width: 20%;
  &:hover {
    background-color: #3f51b5;
  }

  &:focus {
    outline: none;
  }
  &.prevBtn{
    border: 1px solid #4e5fff;
    color: #4e5fff;
    background: white
  }
}

button[disabled] {
  background-color: #ccc;
  cursor: default;
}

/* Style for navigation buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  bottom: 50px;
  right: 50px;
}

/* Adds a subtle progress indicator at the top */
.progress-bar {
  height: 4px;
  width: 100%;
  background: #e0e0e0;
  position: relative;
  margin-bottom: 30px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% / 2 * var(--step)); /* Adjust the denominator to match the number of steps */
    background: #5c6bc0;
    transition: width 0.3s ease;
  }
}
</style>

  </style>
  