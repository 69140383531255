<template>
  <div>
    <vue-splash @loaded="this.loadedMethod()" class="as" :show="loaded" :logo="logo" title="Welcome to AppEQ"
      color="blue" :size="300" :fixed="true" />
    <div class="wrapper">

      <modal class="modal-plg" v-if="this.startTicketPlg" :show="this.startTicketPlg">
        <TicketPlg @close="startTicketPlg = false"></TicketPlg>
      </modal>
      <top-navbar class="backGround" :email="email" :name="name" :pic="pic" :authenticated="authenticated" :isAdmin="isAdmin"></top-navbar>
      <div class="right_panel_sidebar_wrapper">

        <side-bar class="backGround" :expand="expanded">

          <template slot="links">
            <div class="profile-info">
              <img width="105px" src="../../assets/appeq.png" />
            </div>

            <!-- <div class="profile-info">
              <img v-if="pic" :src="pic" />
              <div v-if="!pic" class="profileimage">
              <BigHead
              style="width:60px"
              graphic="vue"
              body="chest"
              mouth="openSmile"
                          eyes='normal'
                          skinTone='light'
                          shape="circle"

              />
              </div>
              <h4 v-if="authenticated" class="profile-name">Hello {{ name }}</h4>
              <h4 v-if="authenticated" class="profile-admin">({{ isAdmin?'ADMIN':'USER' }})</h4>

              <button v-if="!authenticated" @click="handleLogin()" class="loginButton">Login</button>
            </div> -->
            <hr class="hr-line" style="width:60%;">

            <div class="toggle_sidebar_div" @click="handleToggleSidebarClick" :class="{ expandedArrowDiv: expanded }">

              <i style="font-size: 12px;font-weight: 700;" v-if="!expanded" class="tim-icons icon-minimal-right"></i>
              <i style="font-size: 12px;font-weight: 700;" v-if="expanded" class="tim-icons icon-minimal-left"></i>

            </div>

            <div class="sidebarNavDiv">
              <div class="sideBarMain" v-for="item of sideBarItems">
                <h6 v-if="expanded">{{ item.heading }}</h6>
                <div @click="redirectToItem(subItem)"class="sidebarItem" v-if="(subItem.name=='GROWTH HUB' && email.split('@')[1]=='appeq.ai') || (subItem.name!='GROWTH HUB')" v-for="subItem of item.items">
                    <div style="display: flex; align-items: center;">            
                     <img :style="{opacity:expanded?'0.6':'0.8'}" v-if="subItem.icon" :src="getImgUrl(subItem.icon)" alt="" >
                     <div class="sidebarItemName">
                      <span v-if="expanded" >
                    {{ subItem.name }}

                  </span>
                     </div>
                </div>

                </div>
            <hr v-if="!expanded" class="hr-line" style="width:60%;opacity: 0.6;">

              </div>
            </div>

            <!-- <div class="support-div">
              <hr class="hr-line" style="width:100%;margin-bottom: -10px;"><br>
              <div @click="handleLogout()" class="support-div-content">
                <p class="logoutButton" v-if="authenticated"><i class="tim-icons icon-button-power"></i>Logout</p> -->
            <!-- </div> -->
            <!-- </div> -->
          </template>
        </side-bar>



        <div class="right-panel">
          <dashboard-content v-if="authenticated" @click.native="toggleSidebar"> </dashboard-content>

          <div v-if="!authenticated" class="Logout-box">
            <img @click="handleLogin()" src="../../../public/img/login.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import '../../assets/sass/black-dashboard/custom/themes';

.as {
  z-index: 9999999;
}

.right-panel {
  h1 {
    margin-left: 37px;
    margin-top: -70px;
    color: $sidebar-text-color !important;
  }
}


.logoutButton {
  cursor: pointer;
  transition: background-color 0.2s ease 0s;
  margin: left 20px;

}

h4 {
  text-transform: capitalize;
}

.loginButton {
  width: 120px !important;
  color: $login-button-color !important;
  margin-top: 10px;
  cursor: pointer;
  background-color: $login-button-background-color !important;
  border: 1px solid $login-button-border-color !important;
  transition: 0.5s;
  font-weight: 500 !important;
  border-radius: 6px;
  border: 1px solid #6f55ff;
  transition: 0.5s;
}

.loginButton:hover {
  scale: 1.1;
  color: $login-button-background-color !important;
  background-color: $login-button-color !important;
  transition: 0.5s;
}

h1 {
  text-transform: capitalize !important;
}

.table-title {
  padding: 10px 30px 10px 37px;
  margin-bottom: -5px;
}

.expandDiv {
  width: 63px;
  z-index: 9999999999;

  .profile-info {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    height: 90px;

    .profile-name {
      color: transparent !important;
      transition: 0.2s ease-in-out;
      position: absolute;
      margin-top: 80px;
      overflow: hidden;
    }

    .profile-admin {
      color: transparent !important;
      transition: 0.2s ease-in-out;
      position: absolute;
      margin-top: 100px;
      overflow: hidden;
    }
  }

  .support-div-content {

    transition: 0.3s ease-in-out;

  }


}

.expanded {
  width: 250px !important;

  .profileimage {
    height: 70px;
    width: 70px;
    transition: 0.2s ease-in-out;
  }

  .profile-info {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;

    .profile-name {
      margin: auto;
      display: block;
      font-size: 100%;
      margin-bottom: 10px;
      transition: 0.2s ease-in-out;
      color: #333 !important;
      position: absolute;
      margin-top: 100px;
      overflow: hidden;
    }

    .profile-admin {
      margin: auto;
      display: block;
      font-size: 100%;
      margin-bottom: 10px;
      transition: 0.2s ease-in-out;
      color: #333 !important;
      position: absolute;
      margin-top: 120px;
      overflow: hidden;
      font-size: 10px;
    }
  }

  .support-div-content {
    margin: none !important;
    padding: none !important;
    margin-left: -25px !important;
    transition: 0.3s ease-in-out;

  }

  // &:after{
  //   position: absolute;
  //   content: "";
  //   top: 0px;
  //   left: 250px;
  //   background: #0000004d;
  //   width: 100vw;
  //   content: "";
  //   position: absolute;
  //   background-position: 100%;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   right: 0;
  //   bottom: 0;
  //   top: 0;
  //   left: 0;
  //   z-index: 1;
  // }
}

.profile-info {
  img {
    border-radius: 50%;
  }
}

.sidebarNavDiv {
  padding-bottom: 50px;

  .nested_menu_div_widget_setting {
    margin-left: 20px;
    margin-right: 20px;
    position: fixed;
    left: 45px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    min-width: 175px;
    top: 30%;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;

  }

  .nested_menu_div_admin_settings {
    margin-left: 20px;
    margin-right: 20px;
    position: fixed;
    left: 45px;
    background-color: white;
    min-width: 175px;
    padding: 10px;
    border-radius: 5px;
    top: 52%;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }

  .nested_menu_div_widget_setting_expanded,
  .nested_menu_div_admin_settings_expanded {
    margin-left: 25px;
  }

  .close-icon {
    position: absolute !important;
    cursor: pointer;
    right: 2px;
    font-size: 14px !important;
    top: 2px;
    outline: 0;
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
    color: #9f9f9f;
    opacity: 0.6;

    &:hover {
      scale: 1.09;
      color: black;
    }
  }
}

.sidebarNavDiv::-webkit-scrollbar {
  width: 0px;
}

.sidebarNavDiv::-webkit-scrollbar-track {
  width: 0px;
}

.toggle_sidebar_div {
  border-radius: 50%;
  height: 27px;
  display: flex;
  width: 28px;
  margin-right: -11px;
  align-items: center;
  justify-content: center;
  // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  background: #E9F1FB;
  z-index: 9999999999999;
  position: fixed;
  left: 53px;
  top: 109px;
  border: 1px solid #d6d4d4;

  i {
    padding: 0 !important;
    font-weight: 700;
  }
}

.toggle_sidebar_div.expandedArrowDiv {
  margin-left: 155px;
}
</style>
<script>

import TicketPlg from "../../components/TicketPlg.vue"
import { BigHead } from "vue-bigheads";
import Server from "../../components/API/Server";
import VueSplash from "vue-splash"
import Logo from "../../assets/appeq.png"
import Modal from '@/components/Modal';
import TopNavbar from "./TopNavbar.vue";
import DashboardContent from "./Content.vue";
import AvailableSources from "./Available-sources.vue";
import MobileMenu from "./MobileMenu";
import Keys from "../../pages/Keys.vue";
import mixpanel from "../../analytics/mixpanel";
export default {
  components: {
    TopNavbar,
    VueSplash,
    TicketPlg,
    BigHead,
    DashboardContent,
    MobileMenu,
    Modal,
    AvailableSources,
    Keys
  },
  computed: {
    logo() {
      return Logo;
    }
  },
  data() {
    return {
      name: '',
      role: '',
      sideBarItems: [
        {
          type: "text", heading: "Widgets",
          items: [
            { name: "LAUNCHERS", icon: "./Launcher.png", redirect: "launchers" },
            { name: "CONTENTS", icon: "./Content.png", redirect: "contents" }

          ]

        },
        {
          type: "text", heading: "Automations",
          items: [
            { name: "TASK PLAYBOOK", icon: "./Task Playbook.png", redirect: "task-playbook" },
            { name: "EMAIL ASSISTS", icon: "./Email Assist.png", redirect: "email-assists" },
          ]

        },
        {
          type: "text", heading: "Signals",
          items: [
            { name: "EARLY WARNING", icon: "./Early Warning.png", redirect: "early-warning" },
          ]

        },
        {
          type: "text", heading: "DATA",
          items: [
            { name: "BUSINESS OBJECT", icon: "./Data Integration.png", redirect: "business-object" },
            { name: "INTEGRATION", icon: "./Business Objects.png", redirect: "integrations" },

          ]

        },
        {
          type: "text", heading: "ADMIN",
          items: [
            { name: "USERS", icon: "./User Management.png", redirect: "user-managment" },
            // { name: "ONLINE SUPPORT", icon: "./Support Center.png", redirect: "support-center" },
            {name: "ALLOWED APPS", icon: "./successPad.png", redirect: "allowed-apps"},
            {name: "GROWTH HUB", icon: "./growthHub.png", redirect: "growth-hub"}

          ]

        }
      ],
      isAdmin: false,
      showExecutive: true,
      showE: false,
      showCustomFields: true,
      showappeqTeam: true,
      expanded: false,
      showD: false,
      handleshow: false,
      loaded: false,
      showG: false,
      showA: false, // for default closing  
      showB: false, // for default closing widget
      showC: false, // for default closing widget 
      showF: false,
      startTicketPlg: false,
      authenticated: false,
      pageName: '',
      pic: '',
      email: '',
    }
  },
  methods: {
    redirectToItem(link){
      this.$router.push("/"+link.redirect)
    },
    getImgUrl(pet) {
      console.log(pet)
    var images = require.context('../../assets/img', false, /\.png$/)
    return images(pet)
  },
    loadedMethod() {
      console.log("here")
      this.loaded = false;
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    handleLogout() {
      localStorage.clear();
      this.pic = '';
      this.name = ''
      this.authenticated = false;
      this.$router.push("/");
      this.$toasted.success("Successfully logged out")
      document.documentElement.style.setProperty("--wrapper-background-color", "#f7f8ff7a");
      document.documentElement.style.setProperty("--sidebar-background-color", "white");
      document.documentElement.style.setProperty("--sidebar-text-color", "#333");
      document.documentElement.style.setProperty("--login-button-background-color", "white");
      document.documentElement.style.setProperty("--login-button-color", "#333");
      document.documentElement.style.setProperty("--login-button-border-color", "white");
      document.documentElement.style.setProperty("--hr-line-color", "#333");
      document.documentElement.style.setProperty("--table-background-color", "white");
      document.documentElement.style.setProperty("--table-column-heading-color", "black");
      document.documentElement.style.setProperty("--table-text-color", "#333");
      document.documentElement.style.setProperty("--icon-filter", "invert(89%) sepia(0%) saturate(2340%) hue-rotate(42deg) brightness(115%) contrast(100%)");
    },
    handleLogin() {
      this.$router.push("/")
    },
    handleLeave(name) {


      if (name == 'A' && !this.expanded) {
        this.showA = false;
      }
      else if (name == 'B' && !this.expanded) {
        this.showB = false;
      } else if (name == 'C' && !this.expanded) {
        this.showC = false;
      } else if (name == 'D' && !this.expanded) {
        this.showD = false;
      } else if (name == 'E' && !this.expanded) {
        this.showE = false
      } else {
        this.showG = false
      }
      this.showA = false;
      this.showB = false;
      this.showC = false;
      this.showD = false;
      this.showE = false;

    },
    resetShowFlags() {
      this.showA = false;
      this.showB = false;
      this.showC = false;
      this.showD = false;
      this.showE = false;
      this.showG = false
    },
    handleToggleSidebarClick() {
      try {

        this.expanded = !this.expanded;
        this.showA = false;
        this.showC = false;
        this.showD = false;
        this.showE = false;
        this.showG = false



        this.showB = false;
        localStorage.setItem("expanded", this.expanded);
      } catch (e) {
        console.log(e);
      }
    }
  },
  async mounted() {
    try {
      // this.showB=  JSON.parse(localStorage.getItem("showB"))
      if (localStorage.getItem("features") && JSON.parse(localStorage.getItem("features"))) {
        const features = JSON.parse(localStorage.getItem("features"))
        if (features && Array.isArray(features)) {
          features.map(data => {
            console.log(data.feature_name + "0---0" + data.enabled)

            if (data.feature_name == "appeqExecutiveDashboard" && data.enabled == false) {
              this.showExecutive = false
            }
            if (data.feature_name == "appeqTeam" && data.enabled == false) {
              this.showappeqTeam = false

            }
            if (data.feature_name == "appeqCustomFields" && data.enabled == false) {
              this.showCustomFields = false
            }
          })
        }
      }
      this.showB = false;
      this.expanded = JSON.parse(localStorage.getItem("expanded"))
      let data = localStorage.getItem('ADMIN_CREDS');
      if (data) {
        const res = JSON.parse(data);
        if (res['picture'])
          this.pic = res['picture']
        if (res['__email__id']) {
          this.authenticated = true;
          this.name = res['__email__id'].toString().split('@')[0]
          console.log(res['__email__id'])
          this.email = res['__email__id'];
          const val = this.$CryptoJS.HmacSHA256(res['__email__id'], "DYyAKzBo70ca9aCSLNgwzLutBhkw0EXs6Iqj7jI7").toString(this.$CryptoJS.enc.Hex)
          console.log(val);
          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "kosjv7i1",
            email: res['__email__id'],
            name:this.name,
            user_hash: val
            // cutomise installation with your attributes 
          }
        }
      }
      const admin = localStorage.getItem('IS_ADMIN');
      const role = localStorage.getItem('role');
      this.role = role
      if (admin) {
        this.isAdmin = JSON.parse(localStorage.getItem('IS_ADMIN'))
      }
      this.pageName = this.$router.currentRoute;
      if (this.$route.query.ticketPlg && localStorage.getItem("stepNumberPlg") != 5) {
        this.loaded = true
        if (this.$route.query.appName == "freshDesk") {
          localStorage.setItem("ticketPlgAppName", "freshdesk");
          mixpanel.openedTicketPlg(this.name, this.email, "freshdesk");
        } else {
          localStorage.setItem("ticketPlgAppName", "zendesk");
          mixpanel.openedTicketPlg(this.name, this.email, "zendesk");
        }
        const func = (() => new Promise((res) => setTimeout(res, 2800)));
        await func()
        this.loaded = false

        this.startTicketPlg = true;

      }
      console.log(this.isAdmin)
    } catch (error) {
      console.log(error);
    }
  },
  beforeRouteUpdate(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    // persist this.search in localstorage or wherever you like it to be stored
    //  localStorage.setItem("showB",this.showB)
    localStorage.setItem("expanded", this.expanded)
    next()

  },

};
</script>
<style>
.sidebarItem{
  margin-top: 20px;
  font-size: .8rem;
  cursor: pointer;
  font-family: Poppins,sans-serif;
  font-weight: 600;
  color: #545454;


  img{
    height: 28px;
    opacity: 0.6;
    margin-right: 15px;
    margin-left: 15px;
  }
}

.sidebarItemName:hover{
  transform: scale(1.2);
}
.sideBarMain{
  margin-top: 30px;
  h6{
    color: #a6a6a6 !important;
    margin-left: 15px;
  }
}
.backGround{
  background-color: red !important;
  background: red !important;

}
.right-panel{
  /* background: #F4F7FB !important; */
  background-image: url("../../assets/img/appeq_bg_img.png") !important;
  /* background-image: url("../../assets/img/appeq_bg_img.png") !important; */

  background-repeat: repeat !important;
  background-position: center !important;

}
</style>
<style>
  .card{
    border-radius: 20px !important;
  }
</style>