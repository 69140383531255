<template>
    <div class="note-container" :style="{ 'background-color': 'rgb(249, 244, 174)' }">
      <div class="note-header">
        <h5 style="    margin-top: 10px;
    margin-left: 5px;
    margin-bottom: 0px;
    text-transform: capitalize;
">Notes for <span style="font-weight: 600;">{{ getFeedName(account_domain) }}</span></h5>
        <span id="autosave">(Auto saving)</span>
        <img @click="handleSync" src="../icons/sync.png" v-tooltip="'Save to CRM'" style="height: 18px;cursor: pointer;" />
        <div class="note-controls">
          <img @click="handleMinimize()" class="icon" src="../icons/minimize.png" />
          <img @click="handleMinimize(true)" class="icon close" src="../icons/Close.svg" />
        </div>
      </div>
      <div class="note-timestamp">
        <span>{{ getHumanDate(lastUpdatedAt) }} ago</span>
      </div>
      <div class="note-content noscroll" v-if="!minimized">
        <div v-if="!loading">
        <div ref="editor" class="quill-editor"></div>
    </div>
        <div @click="openAudits" class="history-link">View History</div>
        <img v-if="loading" class="loading-icon" src="../icons/loadingripple.svg" />
      </div>
    
    </div>
 
  </template>
  
<script>
import Server from "../components/API/Server";
import { JWT } from "node-jsonwebtoken";

// import { VueEditor } from "vue2-editor";
// import wysiwyg from "vue-wysiwyg";
import VueWysiwyg from 'vue-wysiwyg';
import pell from 'pell'
// or
import { exec, init } from 'pell'


export default {
    components: {
        // wysiwyg
        VueWysiwyg

    },
    data() {
        return {
            account_domain: "",
            state: "",
            showFullPage: false,
            domainList: [],
            loading: false,
            typingTimer: null,
            quillEditor: null,
            showRemoveIcon: -1,
            images: ['../icons/dashboard-ex.svg', "../icons/menu.png", "../icons/dashboard.svg"],
            totalDashboards: [
                {
                    metabaseNumber: 67,
                    metabaseTitle: "Exec Dashboard"
                }
            ],
            dataToSave: '',
            lastUpdatedAt: '',
            showSidePanel: false,
            previousData: '',
            user:'',
            urlFor:""

        }
    },
    props:{
        domain:"",
        appeqkey:"",
        type:"",
        appeqbusinessobject:"",
        crmbusinessobject:"",
        id:""

    },
    async created() {
        this.user=localStorage.getItem("__email__id")
        this.account_domain = this.domain;
                    this.state = this.state;
                    this.showFullPage = true;
                    this.loading = true;
                    const data = (await Server.getNotes(this.account_domain, this.appeqbusinessobject,this.id)).data
                    if (data && data.data && data.data.length > 0) {
                        this.dataToSave = data.data[0].data
                        this.previousData = this.dataToSave
                        this.lastUpdatedAt = data.data[0].timestamp
                    }
                    this.loading = false;
                    const wait = (ms) => new Promise((res) => setTimeout(res, ms));
                    wait(100).then(d => {
                        // this.handleInitialize()
                        this.quillEditor = new Quill(this.$refs.editor, {
                             theme: 'snow', // You can change the theme to 'bubble' if you prefer a different style
                        placeholder: 'Enter notes here .....',
                        modules: {
        toolbar: [
          ['bold', 'italic', 'underline'], // B, I, U buttons
          ['link'] // Link button
        ]
      }
                    });
                    if (this.dataToSave) {
      this.quillEditor.clipboard.dangerouslyPasteHTML(this.dataToSave);
    }

                    this.quillEditor.on('text-change', () => {
                        this.dataToSave = this.quillEditor.root.innerHTML;
                    this.onChangeNotes()

                    });
                    })
        // this.checkDashLength()
    },
    methods: {
       async handleSync(){
        try {
            const data=await Server.resyNotes(this.crmbusinessobject || this.appeqbusinessobject,this.dataToSave,this.id)
            console.log(data)
            if(data && data.code==200)
            this.$toasted.success("Successfully saved to CRM ")
            else
            this.$toasted.error(data.msg)
            
        } catch (error) {
            console.log(error)
            this.$toasted.error(error.toString())
        }
        },
        
        //check dash length 
        async checkDashLength() {
            console.log("in dash length")
            const email = localStorage.getItem("__email__id")
            if (localStorage.getItem("appeq_dashboards") && JSON.parse(localStorage.getItem("appeq_dashboards"))) {
                this.totalDashboards = JSON.parse(localStorage.getItem("appeq_dashboards"))
                return
            }
            let data = (await Server.getAllowedApps(email)).data;
            if (data && data.data && data.data.dashboards) {
                let dashboardsTotal = data.data.dashboards
                localStorage.setItem("appeq_dashboards", JSON.stringify(dashboardsTotal))
                this.totalDashboards = dashboardsTotal
            }

        },
        async openDashForTemplates(value){
            feeds.sendActionToTop('__appeq_open_dashboard_templates', {});
        },
        async openDash(dash) {
            console.log(dash)
            const userInfo = (await Server.getUsersData(localStorage.getItem("__email__id"))).data
            let email = localStorage.getItem('__email__id')

            let metabaseNumber = '';

            let customCode = '';
            if (dash && dash.metabaseNumber) {
                metabaseNumber = Number(dash.metabaseNumber);
                customCode = dash.customCode;
            }
            let params = {};
            if (customCode && JSON.parse(customCode)) {
                let resp = JSON.parse(customCode);
                params = resp
                console.log(resp);
                Object.keys(resp).map((d) => {
                    if (resp[d].includes("{{")) {
                        resp[d] = resp[d].replace("{{", "");
                    }
                    if (resp[d].includes("}}")) {
                        resp[d] = resp[d].replace("}}", "");
                    }
                    if(resp[d].includes("email_id")){
                        params[d] = localStorage.getItem("__email__id");

                    }
                    if(resp[d].includes("TODAY()")){
                        const currDate=new Date()
                        if(resp[d].includes("-")){
                            const val=parseInt(resp[d].split("-")[1])
                            currDate.setDate(currDate.getDate()-val)
                        }
                        if(resp[d].includes("+")){
                            const val=parseInt(resp[d].split("+")[1])
                            currDate.setDate(currDate.getDate()-val)
                        }
                        params[d]=currDate.toISOString()

                    }
                    
                })
                console.log(resp);
            }
            if (!metabaseNumber) {
                metabaseNumber = 67;
            }
            if(userInfo && userInfo.data && userInfo.data.org_id)
            this.org_id = userInfo.data.org_id
            var METABASE_SITE_URL = "https://metabase.appeq.ai";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            const jwt = new JWT(METABASE_SECRET_KEY);
            const currentDate = new Date();
            // Add two days to the current date
            currentDate.setDate(currentDate.getDate() + 2);
            console.log(params)
            const token = await jwt.sign({
                resource: { dashboard: metabaseNumber },
                params: (metabaseNumber !== 67) ? params : {
                    appeq_org_id: this.org_id,
                    current_date: currentDate.toISOString()
                },
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=true";

            feeds.sendActionToTop("__appeq_open_executive_dashboard", iframeUrl);

        },
        openAudits() {
            // this.urlFor=`https://app.appeq.ai/v2/audit?email=${this.user}&account_domain=${this.account_domain}&id=${this.id}`
            // feeds.sendActionToTop("__appeq_open_account_audits", { account_domain: this.account_domain?this.account_domain:this.id });
            window.parent.postMessage({action:"__appeq_open_account_audits",data: { account_domain: this.account_domain?this.account_domain:this.id }},"*")
        },
        getFeedName(name) {
            
            if (name) {
                let companyName = name;

                try {
                    if (companyName.toString().split(".").length > 1) {
                        companyName = companyName.toString().split(".")[0];
                    } else if (companyName.toString().split("@").length > 1) {
                        companyName = companyName.toString().split("@")[0];
                    }
                } catch (e) {
                    console.log(e);
                }

                name = companyName;
            }

            return name;
        },
        handleClose(){
            this.urlFor=""
        },
        async handleCloseIframe() {

            feeds.sendActionToTop("__appeq_close_side_notes", { domains: localStorage.getItem("notes_domain") })
        },
        getHumanDate: function (date) {
            if (!date) {
                return "0 seconds "
            }
            var date = new Date(date)
            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = Math.floor(seconds / 31536000);

            if (interval > 1) {
                return interval + " years";
            }
            interval = Math.floor(seconds / 2592000);
            if (interval > 1) {
                return interval + " months";
            }
            interval = Math.floor(seconds / 86400);
            if (interval > 1) {
                return interval + " days";
            }
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
                return interval + " hours";
            }
            interval = Math.floor(seconds / 60);
            if (interval > 1) {
                return interval + " minutes";
            }
            return Math.floor(seconds) + " seconds";
        },
        handleInitialize() {
            document.getElementById('editor').addEventListener("click", (ev) => {
                const clickedElement = ev.target;

                if (clickedElement.tagName === 'A') {
                    const linkHref = clickedElement.getAttribute('href');
                    window.open(linkHref, "_blank"); // Redirect to the link
                }
            })
            const editor = init({
                element: document.getElementById('editor'),
                onChange: html => {
                    this.dataToSave = html.toString()
                    // document.getElementById('html-output').textContent = html
                    this.onChangeNotes()
                },
                defaultParagraphSeparator: 'p',
                styleWithCSS: true,
                actions: [
                    'bold',
                    'underline',

                    {
                        name: 'link',
                        result: () => {
                            const url = window.prompt('Enter the link URL')
                            if (url) exec('createLink', url)
                        }
                    }
                ],
                classes: {
                    actionbar: 'pell-actionbar-custom-name',
                    button: 'pell-button-custom-name',
                    content: 'pell-content-custom-name',
                    selected: 'pell-button-selected-custom-name'
                }
            })

            // editor.content<HTMLElement>
            // To change the editor's content:
            editor.content.innerHTML = this.dataToSave

        },
        onChangeNotes() {
            if (this.typingTimer) {
                clearTimeout(this.typingTimer);
            }

            this.typingTimer = setTimeout(async () => {
                const wait = (ms) => new Promise((res) => setTimeout(res, ms));
                document.getElementById("autosave").style.opacity = 1
                console.log(this.appeqbusinessobject)
                console.log(this.appeqkey)
                await Server.saveNotes(this.dataToSave, this.account_domain,this.id,this.appeqbusinessobject,this.appeqkey);
                await wait(500)
                document.getElementById("autosave").style.opacity = 0

            }, 1200);
        },
        getIcon(context_link) {
            try {
                if (context_link == null)
                    return 'https://logo.clearbit.com/' + 'zendesk.com';
                else if (context_link.toString().split("@").length > 1)
                    return 'https://logo.clearbit.com/' + context_link.toString().split("@")[1];

                else if (context_link.split(".").length > 1 ? "" : ".com")
                    return 'https://logo.clearbit.com/' + context_link.toString().split(" ").join("") + ".com";
                else
                    return 'https://logo.clearbit.com/' + context_link;

            } catch (e) {
                console.log(e);
            }
        },
        handleOpenNotes(dom) {
            feeds.sendActionToTop("__appeq_open_notes_iframe_summary", { domain: dom, state: 'private' })

        },
        handlePublicNotes(dom) {
            feeds.sendActionToTop("__appeq_open_notes_iframe_summary", { domain: dom, state: 'public' })
        },
        async handleMinimize(close) {
            console.log(this.dataToSave);
            console.log(this.previousData);
            if(this.previousData != this.dataToSave)
            await Server.saveAudit(this.dataToSave, this.account_domain?this.account_domain:this.id)

            if (this.state == "public" && this.previousData != this.dataToSave) {
                console.log('working')
            }
            if (close) {
                this.$emit("close")
                // feeds.sendActionToTop("__appeq_minimize_notes_close", {});
                // feeds.sendActionToTop("__appeq_notes_add", { domains: localStorage.getItem("notes_domain") })
                return;
            }
            const domains = localStorage.getItem("notes_domain") && JSON.parse(localStorage.getItem("notes_domain")) ? JSON.parse(localStorage.getItem("notes_domain")) : []
            if (domains.findIndex(d => d == this.account_domain) == -1) {
                domains.push(this.account_domain)
                localStorage.setItem("notes_domain", JSON.stringify(domains))
            }
            this.$emit("close")

            // feeds.sendActionToTop("__appeq_minimize_notes_close", {});
            // feeds.sendActionToTop("__appeq_notes_add", { domains: localStorage.getItem("notes_domain") })
        },
        handleRemoveDomain(idx) {
            this.domainList.splice(idx, 1)
            localStorage.setItem("notes_domain", JSON.stringify(this.domainList))

        }
    }
}
</script>
<style lang="scss" scoped>
.note-container {
  background-color: rgb(249, 244, 174);
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.note-header {
  display: flex;
  justify-content: space-between;
  background: rgb(254, 266, 174) !important;
  align-items: center;
  padding: 8px;
  h3{
    margin-bottom: 0px;
  }
}

.note-controls {
  display: flex;
  align-items: center;
}

.icon {
  height: 15px;
  cursor: pointer;
}

.icon.close {
  margin-left: 10px;
}

.note-timestamp {
  text-align: right;
  padding-right: 10px;
  font-size: 9px;
  margin-top: 5px;
}

.note-content {
  height: 82vh;
  padding: 5px;
  overflow: auto;
  padding-left: 10px;
}

.history-link {
  position: fixed;
  right: 15px;
  font-size: 10px;
  bottom: 40px;
  color: blue;
  cursor: pointer;
}

.loading-icon {
  height: 45px;
  display: block;
  margin: 20px auto;
}

#autosave {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
</style>

<style lang="scss">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";

.ck.ck-editor__main>.ck-editor__editable {
    background: transparent;
    border-radius: 0;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    background-color: transparent !important;
    border: none !important;
}

.ck.ck-editor__main>.ck-editor__editable.ck-focused {
    background-color: transparent !important;
    border: none !important;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    background-color: transparent !important;
    border: none !important;
}

.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
    position: fixed;
    bottom: 3px;
    padding-left: 5px;
}

.ck .ck-powered-by__label {
    display: none !important;
}

.pell-button-custom-name {
    background-color: transparent;
    outline: none;
    border: none;
}

.pell-actionbar-custom-name {
    position: fixed;
    bottom: 3px;
    display: none;
}

.pell {
    border: none !important;
}

.pell-content-custom-name:focus {
    outline: none;
}

.pell-content-custom-name {
    height: 100%;
    overflow: auto;
}

.pell-content-custom-name::-webkit-scrollbar {
    width: 0px !important;
}

.ck.ck-editor__main>.ck-editor__editable {
    background-color: none !important;
}

.dashboardInd {
    cursor: pointer;
}

.dashboardInd img:hover {
    transform: scale(1.1);
    /* Scale the image by 10% on hover */

}</style>

<style lang="css">
@import "./pell.css";
</style>
<style lang="scss">
.noscroll::-webkit-scrollbar-thumb{
    width: 0px !important;
}
.noscroll::-webkit-scrollbar{
    width: 0px !important;
}
.placeholder {
  color: #999;
  pointer-events: none;
}
.pell {
  min-height: 100px;
}
.ql-toolbar.ql-snow{
    border: none !important;
    display: flex !important;
    align-items: center !important;
}
.quill-editor.ql-container.ql-snow{
    border: none !important;

}
.ql-formats{
    display: flex !important;
    align-items: center !important;
}
</style>