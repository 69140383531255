<template>
    <div>
        <iframe :src=iframeUrl frameborder="0" width="1200" height="720" allowtransparency></iframe>
    </div>
</template>

<script>
import Server from "../../components/API/Server";
import Modal from "../../components/Modal.vue";
import ConfiguratorVue from "../../components/Configurator.vue";
import jwt from "jsonwebtoken"
import { JWT } from "node-jsonwebtoken";
import LocalUtils from "../../components/Utils/LocalUtils";
import moment from "moment";

export default {
    components: {
        Modal,
        ConfiguratorVue
    },
    data() {
        return {
            allCreds: [],
            showSettingsModal: false,
            selectedBusinessObj: '',
            appeq_supported_application: '',
            iframeUrl: '',
            org_id: ""
        }
    },

    async mounted() {
        const userInfo = (await Server.getUsersData(LocalUtils.getEmail())).data
        this.org_id = userInfo.data.org_id
        console.log(userInfo)
        var METABASE_SITE_URL = "https://app.appeq.ai/bi";
        var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
        const jwt = new JWT(METABASE_SECRET_KEY);
        const currentDate = new Date();
        const data = await Server.getAllowedApps();
        console.log(data.data.data);
        let dashboards = [];
        let metabaseNumber = '';
        let customCode = '';
        if(data && data.data && data.data.data && data.data.data.dashboards){
            dashboards = data.data.data.dashboards;
        }
        for(let i=0;i<dashboards.length;i++){
            if(dashboards[i].executive && dashboards[i].executive == true){
                metabaseNumber = dashboards[i].metabaseNumber;
                if(dashboards[i].customCode){
                    customCode = dashboards[i].customCode;
                }
            }
        }
        metabaseNumber = Number(metabaseNumber);
        if(!metabaseNumber){
            metabaseNumber = 67;
        }
        let params = {};
        if(customCode && JSON.parse(customCode)){
            let resp = JSON.parse(customCode);
            params=resp
            console.log(resp);
            Object.keys(resp).map((d)=>{
                if(resp[d].includes("{{")){
                    resp[d] = resp[d].replace("{{","");
                }
                if(resp[d].includes("}}")){
                    resp[d] = resp[d].replace("}}","");
                }
                switch(resp[d]){
                    case 'email_id':
                        params[d] = localStorage.getItem("__email__id");
                        break;
                    
                }
            })
            console.log(resp);
        }

        // Add two days to the current date
        currentDate.setDate(currentDate.getDate() + 2);

        const token = await jwt.sign({
            resource: { dashboard: (metabaseNumber!=67) ? metabaseNumber : 67 },
            params: (metabaseNumber!=67) ? {params} : {
                appeq_org_id: this.org_id.toString(),
                current_date: currentDate.toISOString()
            },
            exp: Math.round(Date.now() / 1000) + (10 * 60)
        });
        // const payload = await jwt.verify(token);

        // var token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjY2fSwicGFyYW1zIjp7fSwiZXhwIjoxNjk0NTk2NzY3fQ.PBRixXVIzidQJJMTgvWux5ZwTJLrr6vF99wEkA56ctI'
        this.iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";
    }
}
</script>

<style lang="scss">
.feeds {
    width: 100%;

    &__tile:hover {
        background-color: #e3e6fa7a;
    }

    &__tile {
        font-size: 15px;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        padding: 12px 10px;
        font-weight: 600;
        margin-top: 20px;
        width: 100%;
        align-items: center;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        border-radius: 15px;
        padding: 20px;
        width: 95%;
        margin-right: auto;
        margin-left: auto;

        &--logo {
            height: 48px;
            width: 48px;

            img {
                height: 48px;
                width: 48px;

            }
        }

        &--title {
            margin-left: 10px;
            flex: 1;

        }

        &--heading {
            text-transform: capitalize;

        }

        &--subheading {
            font-weight: 400;
            font-size: 13px;
            text-transform: capitalize;
        }
    }

    .settings {
        cursor: pointer;
        height: 30px;
        filter: invert(0%) sepia(0%) saturate(7489%) hue-rotate(138deg) brightness(0%) contrast(98%);
        transition: 0.2s ease-in-out;

        &:hover {
            scale: 1.2;
            transition: 0.2s ease-in-out;
        }
    }
}

.mymodal {
    width: 1000px;
}

.modalContent {
    width: 1000px;

}

iframe {
    width: 100%;
}
</style>