const { Base64 } = require("js-base64");
import axios, { Axios } from "axios";
import LocalUtils from "../Utils/LocalUtils";
class MyServer {
  constructor() {
    this.request = axios.create();
    // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  }

  async getIcons() {
    try {
      const data = await axios.get("https://api1.appeq.ai/widget/get-appeq-icons")
      return data.data
    } catch (error) {

    }
  }
  /**
   * desc: A function to authenticate google sheet credentials
   * @param {*} body
   * @returns
   */
  async getgoogleSheetData(body) {
    try {
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/google-sheet/authenticate`,
        body,
        {
          headers: {},
        }
      );
      console.log(data.data);
      return data.data;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * @desc: A function to fetch admin configs from api and show data
   * @returns
   */
  async getAdminConfigs() {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/configurator/get-admin-configs`,
          { email: LocalUtils.getEmail() }
        )
      ).data;
    } catch (error) {
      return error;
    }
  }


  async getAdminConfigsForSumm() {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/configurator/get-admin-configs`,
          { email: localStorage.getItem("__email__id") }
        )
      ).data;
    } catch (error) {
      return error;
    }
  }

  /**
   * @desc : A funtion to get google sheet keys
   * @param {*} body
   * @returns
   */
  async getGoogleSheetKeys(body) {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/google-sheet/keys`,
          body
        )
      ).data;
    } catch (error) { }
  }

  /**
   * @desc : A function to save sheet data
   * @param {*} bdoy
   * @returns
   */
  async saveSheetData(body) {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/google-sheet/save`,
          body
        )
      ).data;
    } catch (error) { }
  }

  // /**
  //  * @desc : A function to check wether user is logged in or not
  //  * @returns
  //  */
  // async checkLogin() {
  //   try {
  //     const data = await this.request.get(
  //       `${process.env.VUE_APP_API_URL}/appeq-login`
  //     );
  //     if (data["data"] && data["data"]["code"] != 404) {
  //       localStorage.setItem("ADMIN_CREDS", JSON.stringify(data["data"]));
  //       return;
  //     }
  //     if (
  //       data["data"] &&
  //       data["data"]["code"] == 404 &&
  //       localStorage.getItem("google_auth")
  //     ) {
  //       localStorage.clear();
  //     }

  //     if (data["data"]["code"] != 500) {
  //       this.request = axios.create({ withCredentials: true });
  //     }

  //     if (data.data.code == 404) {
  //       // localStorage.clear()
  //       return;
  //     }
  //   } catch (error) {
  //     localStorage.clear();

  //     console.log(error);
  //   }
  // }

  async callSceduler(name, bObject) {
    try {
      return await axios.post(
        `${process.env.VUE_APP_API_URL}/org-scheduler/${name}`,
        {
          email: LocalUtils.getEmail(),
          business_object_name: bObject,
        }
      );
    } catch (error) { }
  }
  async sendInvite(emails, profile) {
    try {
      return await axios.post(`${process.env.VUE_APP_API_URL}/send-invite `, {
        email_id: LocalUtils.getEmail(),
        members: emails,
        segment_name: profile.name,
        summary_name: profile.summary_name,
      });
    } catch (error) { }
  }

  /**
   * @desc : A function to get all values from a particular key
   * @returns
   */
  async getAllKeyValue() {
    try {
      return await axios.post(
        `${process.env.VUE_APP_API_URL}/configurator/keys-admin`
      );
    } catch (error) { }
  }

  async checkAdminUser() {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/admin-user`, {
          email: LocalUtils.getEmail(),
        })
      ).data;
    } catch (error) { }
  }

  async givePermissionToSheet(sheet_id, access_token) {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/google-sheet/authenticate-user`,
          { sheet_id: sheet_id, access_token: access_token }
        )
      ).data;
    } catch (error) {
      console.log(error);
    }
  }

  async getUserProfile(access_token) {
    try {
      const instance = new Axios({});
      console.log("Asdkasjfdasfdhsafas");
      const data = await this.request.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`
      );
      return data;
    } catch (error) { }
  }

  async editSummaryName(old, newSum) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/summary/update_name`, {
          email: LocalUtils.getEmail(),
          old_summary_name: old,
          new_summary_name: newSum,
        })
      ).data;
    } catch (error) { }
  }

  async getprompts() {
    try {
      var email_id = LocalUtils.getEmail();
      return (
        await axios.get(`${process.env.VUE_APP_API_URL}/template/get-prompts?email=${email_id}`)
      ).data;
    } catch (error) {

    }
  }

  async getpromptsForDashboard() {
    try {
      var email_id = localStorage.getItem("__email__id")
      return (
        await axios.get(`${process.env.VUE_APP_API_URL}/template/get-prompts?email=${email_id}`)
      ).data;
    } catch (error) {

    }
  }
  async getProductCatalogue() {
    try {
      let email_id = LocalUtils.getEmail();
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/renewals/get-products-catalogue`, {
          email: email_id
        })
      ).data;
    } catch (error) {

    }
  }

  async deleteProductCatalogue(id) {
    try {
      let email_id = LocalUtils.getEmail();
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/renewals/delete-product-catalogue`, {
          email: email_id,
          productId: id
        })
      ).data;
    } catch (error) {

    }
  }

  async addProductCatalogue(data) {
    try {
      let email = LocalUtils.getEmail();
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/renewals/add-product-catalogue`, {
          email: email,
          ...data
        })
      )
    } catch (error) {

    }
  }

  async savePrompt(name, templateType, data, promptId, status) {
    try {
      let email = LocalUtils.getEmail();
      if (templateType == 'text') {
        if (status == true) {
          return (
            await axios.post(`${process.env.VUE_APP_API_URL}/template/save-prompt`, {
              'name': name,
              'promtType': 'Static',
              'promptId': promptId,
              'email': email,
              'templateSubject': data.templateSubject,
              'templateBody': data.templateBody,
              'attachmentLink':data.attachmentLink
            })
          ).data;
        } else {
          return (
            await axios.post(`${process.env.VUE_APP_API_URL}/template/save-prompt`, {
              'name': name,
              'promtType': 'Static',
              'email': email,
              'templateSubject': data.templateSubject,
              'templateBody': data.templateBody
            })
          ).data;
        }
      } else {
        if (status == true) {
          return (
            await axios.post(`${process.env.VUE_APP_API_URL}/template/save-prompt`, {
              'name': name,
              'promtType': 'CGPT',
              'promptId': promptId,
              'email': email,
              'prompt': data.prompt
            })
          ).data;
        } else {
          return (
            await axios.post(`${process.env.VUE_APP_API_URL}/template/save-prompt`, {
              'name': name,
              'promtType': 'CGPT',
              'email': email,
              'prompt': data.prompt
            })
          ).data;
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async deleteEmailTemplate(promptId) {
    try {
      return (
        await axios.delete(`${process.env.VUE_APP_API_URL}/template/save-prompt`, {
          data: {
            'promptId': promptId
          }
        })
      ).data;
    } catch (error) {

    }
  }

  async checkAdmin(userName) {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/userInfo/getUserInfo`,
          {
            username: userName,
          }
        )
      ).data;
    } catch (error) {
      console.log(error);
    }
  }

  async getZohoAccessToken(code) {
    try {
      let res = await axios.post(`${process.env.VUE_APP_API_URL}/zoho/get-access`, {
        code: code
      });
      return res;
    }
    catch (error) {

    }
  }
  async saveWidgetConf(path, data) {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/widget/${path}-widget-config`,
          {
            email: LocalUtils.getEmail(),
            ...data
          }
        )
      ).data;
    } catch (error) {

    }
  }

  async getAccessToken(code) {
    try {
      // const params = new URLSearchParams();
      // params.append("grant_type", "authorization_code");
      // params.append("client_id", "6951dc5d-cab1-43b6-a76e-34a85d504058");
      // params.append("client_secret", "ad944f03-0a0f-49ae-a289-847851ed0f2e");
      // params.append("redirect_uri", "http://localhost:8080/integrations");
      // params.append("code", code);
      // await axios({
      //   method: "post",
      //   url: "https://api.hubapi.com/oauth/v1/token",
      //   data: bodyFormData,
      //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
      // });
      return axios.post(`${process.env.VUE_APP_API_URL}/hubspot/getaccess`, {
        code: code,
      });
      // return (
      //   await axios.post(
      //     "https://api.hubapi.com/oauth/v1/token",
      //     {
      //       form: {
      //         grant_type: "authorization_code",
      //         client_id: "6951dc5d-cab1-43b6-a76e-34a85d504058",
      //         client_secret: "ad944f03-0a0f-49ae-a289-847851ed0f2e",
      //         redirect_uri: "http://localhost:8080/integrations",
      //         code: code,
      //       },
      //     },
      //     {
      //       headers: "content-type: application/x-www-form-urlencoded",
      //     }
      //   )
      // ).data;
    } catch (error) { }
  }

  async checkLoginGoogleWithCheck(email, policyCheck) {
    try {
      const register = await axios.post(
        `${process.env.VUE_APP_API_URL}/appeq-register`,
        {
          email_id: email,
          check: policyCheck,
          platform: "dashboard",
        }
      );
      return register.data;
    } catch (error) {
      // localStorage.clear();
    }
  }

  async checkLoginGoogle(email) {
    try {
      const register = await axios.post(
        `${process.env.VUE_APP_API_URL}/appeq-register`,
        { email_id: email, platform: "dashboard" }
      );
      return register.data;
    } catch (error) {
      localStorage.clear();
    }
  }

  /**
   * @desc : A function to login user from google
   */
  async login() {
    try {
      // const data = await this.request.get(`${process.env.VUE_APP_API_URL}/appeq-login`)
      // if (data.data.code == 404) {
      var win = window.open(
        `${process.env.VUE_APP_API_URL}/oauth2/google/login`,
        "_self"
      );
      // } else
      // console.log(data.data)
      if (data.data) {
        localStorage.setItem("ADMIN_CREDS", JSON.stringify(data.data));
        return data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * @desc : A function to get google sheet creds from email id
   * @returns
   */
  async googleSheetCreds() {
    const data = await axios.post(
      `${process.env.VUE_APP_API_URL}/sheet-credentials/all`,
      {
        email: LocalUtils.getEmail(),
      },
      {
        headers: {
          origin: "https://app.appeq.io",
          a: "as",
        },
      }
    );
    return data.data;
  }
  async googleSheetCredentials(email) {
    const data = await axios.post(
      `${process.env.VUE_APP_API_URL}/sheet-credentials/all`,
      {
        email: email,
      },
      {
        headers: {
          origin: "https://app.appeq.io",
          a: "as",
        },
      }
    );
    return data.data;
  }
  async getAppeqClients() {
    const data = await axios.post(
      `${process.env.VUE_APP_API_URL}/appeq-customers/get`,
      {}
    );
    return data.data;
  }
  async getPlans() {
    const data = await axios.post(
      `${process.env.VUE_APP_API_URL}/plans/get`,
      {}
    );
    return data.data;
  }

  async getSubscriptions() {
    const data = await axios.post(
      `${process.env.VUE_APP_API_URL}/subscription/get`,
      {
        email: LocalUtils.getEmail(),
      }
    );
    return data.data;
  }

  async callHubsPot(bObj) {
    const data = await (
      await axios.post(`${process.env.VUE_APP_API_URL}/hubspot/savedataindb`, {
        email: LocalUtils.getEmail(),
        business_object_name: bObj,
      })
    ).data;
  }

  async getSalesforceaccess(code, clientId, clientSec, domain) {
    const data = await axios.post(
      `${process.env.VUE_APP_API_URL}/salesforce/access_token`,
      { code: code, clientId: clientId, clientSec: clientSec, domain: domain }
    );
    return data;
  }

  /**
   * @desc : A function to call api to register user
   * @param {*} email_id
   * @returns
   */
  registerFirstTimeUser(email_id, policyCheck) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}/register`, {
          email_id: email_id,
          check: policyCheck,
          platform: "dashboard",
        })
        .then((res) => resolve(res))
        .catch((error) =>
          reject("Oops an Error Occurred. Email us at support@appeq.ai")
        );
    });
  }

  getUsersData(email_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}/check-users`, {
          email_id: email_id,
        })
        .then((res) => resolve(res))
        .catch((error) =>
          reject("Oops an Error Occurred. Email us at support@appeq.ai")
        );
    });
  }

  sendPasscode(email_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}/register`, {
          email_id: email_id,
          platform: "dashboard",
        })
        .then((res) => resolve(res))
        .catch((error) =>
          reject("Oops an Error Occurred. Email us at support@appeq.ai")
        );
    });
  }

  internalLogin(email, passcode) {
    const authHeader = `Basic ${Base64.encode(`${email}:${passcode}`)}`;
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/internal-login
          `,
          {
            email_id: email,
            passcode: passcode,
          }
        )
        .then((res) => resolve(res))
        .catch((error) =>
          reject(
            "Passcode did not match. Try again or email us at support@appeq.ai"
          )
        );
    });
  }

  /**
   * @desc : A function to login with email
   * @param {*} email
   * @param {*} passcode
   * @returns
   */
  loginwithEmail(email, passcode) {
    const authHeader = `Basic ${Base64.encode(`${email}:${passcode}`)}`;
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/login`,
          {},
          { headers: { Authorization: authHeader } }
        )
        .then((res) => resolve(res))
        .catch((error) =>
          reject(
            "Passcode did not match. Try again or email us at support@appeq.ai"
          )
        );
    });
  }

  async saveSummary(data, ai, summary_name, profile_name, highLights, trends) {
    try {
      if (profile_name) {
        return await axios.post(
          `${process.env.VUE_APP_API_URL}/summary/summaryfunction`,
          {
            email: LocalUtils.getEmail(),
            summary_name: summary_name,
            profile_name: profile_name,
            data: data,
            ai: ai,
            third_party_data: highLights,
            trends: trends
          }
        );
      } else {
        return await axios.post(
          `${process.env.VUE_APP_API_URL}/summary/summaryfunction`,
          {
            email: LocalUtils.getEmail(),
            summary_name: summary_name,
            data: data,
            ai: ai,
            trends: trends
          }
        );
      }
    } catch (error) { }
  }

  //removed profile name from here
  async delSumm(summName) {
    try {
      return await axios.post(`${process.env.VUE_APP_API_URL}/summary/delete`, {
        email: LocalUtils.getEmail(),
        summary_name: summName,
      });
    } catch (error) { }
  }

  /**
   * @desc : A function to return business data object keys
   * @returns
   */
  async getBusinessDataObs() {
    try {
      const data = (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/business-objects/get`,
          {}
        )
      ).data;
      return data;
    } catch (error) {
      return error;
    }
  }

  /**
   * @desc : A function to get zendesk creds
   * @returns
   */
  async zendeskCredsFetch() {
    try {
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/zendesk/ticket/fetch_credentials_by_user_details`,
        { email: LocalUtils.getEmail() }
      );
      return data.data;
    } catch (error) { }
  }

  async saveKeys(business_object, keys) {
    try {
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/save-keys`,
        {
          email: LocalUtils.getEmail(),
          business_object_name: business_object,
          keys: keys,
        }
      );
      return data.data;
    } catch (error) { }
  }

  async fetchKeys(appeq_supported_application, business_object) {
    try {
      const data = await axios.post(`${process.env.VUE_APP_API_URL}/get-keys`, {
        email: LocalUtils.getEmail(),
        appeq_supported_application: appeq_supported_application,
        business_object_name: business_object,
      });
      return data;
    } catch (error) { }
  }

  async zendeskSubmit(body) {
    try {
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/zendesk/ticket/credentials`,
        body
      );
      return data.data;
    } catch (error) { }
  }

  async getSubscriptions() {
    try {
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/subscription/get`,
        {
          email: LocalUtils.getEmail(),
        }
      );
      return data.data;
    } catch (error) { }
  }

  async freshdeskSubmit(body) {
    try {
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/freshdesk/save`,
        body
      );
      return data.data;
    } catch (error) { }
  }
  async freshdeskCredsFetch(freshdeskEmail, freshdeskDomain, freshdeskToken) {
    try {
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/freshdesk/auth`,
        {
          email: freshdeskEmail,
          domain: freshdeskDomain,
          api_key: freshdeskToken,
        }
      );
      return data.data;
    } catch (error) { }
  }

  async getApps() {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/allowed-apps-widget/query`,
          { email: LocalUtils.getEmail() }
        )
      ).data;
    } catch (error) { }
  }

  async saveAccountsdata(account_domain) {
    const my_email_id = LocalUtils.getEmail();

    let query = { email: my_email_id, account_domain: account_domain };
    return (
      await axios.post(
        `${process.env.VUE_APP_API_URL}/save-accounts-data`,
        query
      )
    ).data;
  }

  async saveNotificationSettings(data) {
    const my_email_id = LocalUtils.getEmail();

    data = { notificationSettings: { ...data }, email: my_email_id };
    return (
      await axios.post(
        `${process.env.VUE_APP_API_URL}/save-notification-settings`,
        data
      )
    ).data;
  }

  async getNotificationSettings() {
    const my_email_id = LocalUtils.getEmail();

    const data = { email_id: my_email_id };
    return (
      await axios.post(
        `${process.env.VUE_APP_API_URL}/get-notification-data`,
        data
      )
    ).data;
  }

  async triggerNotification(domain, name, message, tasks, id) {
    const my_email_id = LocalUtils.getEmail();
    const data = {
      message: message,
      name: name,
      account_domain: domain,
      tasks: tasks,
      email: my_email_id,
      account_id: id
    };
    return (
      await axios.post(
        `https://websocket.appeq.ai/trigger-notification`,
        data
      )
    ).data;
  }

  async changeRoles(summ_name, prof_name) {
    const my_email_id = LocalUtils.getEmail();

    return (
      await axios.post(`${process.env.VUE_APP_API_URL}/roles/replace`, {
        email: my_email_id,
        summary_name: summ_name,
        profile_name: prof_name,
        method: "force",
      })
    ).data;
  }

  async getAccountsDetails(domain, id) {
    const my_email_id = LocalUtils.getEmail();

    return (
      await axios.post(`${process.env.VUE_APP_API_URL}/get-accounts-details`, {
        email: my_email_id,
        domain: domain,
        id: id
      })
    ).data;
  }

  async getAccountsInfo(domain, columns) {
    const my_email_id = LocalUtils.getEmail();

    return (
      await axios.post(
        `${process.env.VUE_APP_API_URL}/master/get-domains-info`,
        {
          email: my_email_id,
          domains: domain,
          columns: columns,
        }
      )
    ).data;
  }

  async callIngestAuth() {
    const my_email_id = LocalUtils.getEmail();

    return (
      await axios.post(`${process.env.VUE_APP_API_URL}/ingest-data/auth`, {
        email: my_email_id,
      })
    ).data;
  }

  async getAccountsData(method, user) {
    const my_email_id = LocalUtils.getEmail();

    let query = { email: my_email_id };
    if (method) {
      query["method"] = "watch";
    }
    if (user) {
      query["email"] = user;
    }
    return (
      await axios.post(
        `${process.env.VUE_APP_API_URL}/get-accounts-data`,
        query
      )
    ).data;
  }

  async authenticateExotel(apiKey,apiToken,accountSid){
    return (await axios.post(`${process.env.VUE_APP_API_URL}/exotel/authenticate`,{  
        "api_key": apiKey,
      "api_token": apiToken,
      "sid": accountSid,
      "email": LocalUtils.getEmail()
    }))
  }

  async authenticateChargeBee(apiKey,apiToken,bObject){
    return (await axios.post(`${process.env.VUE_APP_API_URL}/chargebee/authenticate-and-save`,{  
      "api_key": apiKey,
      "site": apiToken,
      "email": LocalUtils.getEmail(),
      "business_object_name": bObject

    }))
  }

  async saveExotel(apiKey,apiToken,accountSid,bObjecs){
    return (await axios.post(`${process.env.VUE_APP_API_URL}/exotel/save`,{  
      "api_key": apiKey,
    "api_token": apiToken,
    "sid": accountSid,
    "business_object_name": bObjecs,
    "email": LocalUtils.getEmail()
  }))
  }

  async getMyCustomers() {
    const my_email_id = localStorage.getItem("__email__id");

    let query = { email: my_email_id };
    // if (method) {
    //   query["method"] = "watch";
    // }
    // if (user) {
    //   query["email"] = user;
    // }
    return (
      await axios.post(
        `${process.env.VUE_APP_API_URL}/get-accounts-data`,
        query
      )
    ).data;
  }

  async updateAccountData(data) {
    const my_email_id = LocalUtils.getEmail();

    let query = { email: my_email_id, data };

    return (
      await axios.post(`${process.env.VUE_APP_API_URL}/account/update`, query)
    ).data;
  }

  async getTrendingData(account_domain, bObj) {
    return (
      await axios.post(`${process.env.VUE_APP_API_URL}/clickhouse/get`, {
        query: `Select * from trending WHERE account_domain=='${account_domain}' AND business_object_name='${bObj}'`,
        email: LocalUtils.getEmail(),
      })
    ).data;
  }

  async getAllowedDomains() {
    try {
      const email = LocalUtils.getEmail();
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/summary/allowed-domains`,
        {
          email: email,
        }
      );
      return data.data;
    } catch (error) { }
  }


  async readPulseData(domain, id) {
    const my_email_id = localStorage.getItem("__email__id");
    return (
      await axios.post(`${process.env.VUE_APP_API_URL}/summary/accounts-data`, {
        email: my_email_id,
        account_domain: domain,
        "function": "pulsesettings",
        "business_object_name": "pulse",
        id: id
      })
    ).data;
  }
  async savePulse(query) {
    try {
      const email = localStorage.getItem("__email__id");
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/pulse/save`,
        {
          email: email,
          ...query,
        }
      );
      return data.data;
    } catch (error) { }
  }

  async getAllAllowedDomains(value, page, perPage) {
    try {
      const email = LocalUtils.getEmail();
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/summary/allowed-domains-paginated`,
        {
          email: email,
          page: page,
          per_page: perPage,
          searchStr: value,
        }
      );
      return data.data;
    } catch (error) { }
  }



  async getAllAllowedDomainsforDashboard(value, page, perPage) {
    try {
      const email = localStorage.getItem("__email__id");
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/summary/allowed-domains-paginated`,
        {
          email: email,
          page: page,
          per_page: perPage,
          searchStr: value,
        }
      );
      return data.data;
    } catch (error) { }
  }


  async searchAllowedDomains(value) {
    try {
      const email = LocalUtils.getEmail();
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/summary/allowed-domains`,
        {
          email: email,
          searchStr: value,
        }
      );
      return data.data;
    } catch (error) { }
  }
  /**
   * @desc :   function to check allowed apps and show
   */
  async getAllowedApps() {
    try {
      const email = LocalUtils.getEmail();
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/success-pad/get-apps`,
        { email_id: email }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async getAllowedApps2(email) {
    try {
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/success-pad/get-apps`,
        { email_id: email }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * @desc :   function to check allowed apps and show
   */
  async saveAllowedApps(apps) {
    try {
      const orgName = LocalUtils.getEmail().split("@")[1];
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/success-pad/save-apps`,
        {
          org_name: orgName,
          apps: apps,
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * @desc :   function to check allowed apps and show
   */
  async selectPlan(q, plan) {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("email_id", LocalUtils.getEmail());
      bodyFormData.append("plan_id", (plan + 1).toString());
      bodyFormData.append("quantity", q);

      const orgName = LocalUtils.getEmail().split("@")[1];
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/create-checkout-session`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async storeProfilePic(email, prof_pic, name) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/update-user`, {
          email_id: email,
          name: name,
          profile_url: prof_pic,
        })
      ).data;
    } catch (error) { }
  }

  async authenticateJira(email, apikey, baseurl) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/jira/authenticate-and-fetch-projects`, {
          jira_email: email,
          api_token: apikey,
          base_url: baseurl
        })
      )
    } catch (error) {

    }
  }

  async connectJira(email, apikey, baseurl, projectKey) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/jira/save`, {
          jira_email: email,
          user_email: LocalUtils.getEmail(),
          base_url: baseurl,
          api_token: apikey,
          project_key: projectKey
        })
      )
    } catch (error) {

    }
  }

  async authenticateRocketlane(apikey) {
    try {
      let email = LocalUtils.getEmail();
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/rocketlane/authenticate-and-save`, {
          user_email: email,
          api_key: apikey
        })
      )
    } catch (error) {

    }
  }

  async authenticateERP(username, password, baseurl, business_object_name) {
    try {
      let user_email = LocalUtils.getEmail();
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/logicerp/authenticate-and-save`, {
          username, password, baseurl, business_object_name, user_email
        })
      )
    } catch (error) {

    }
  }

  callApi(url) {
    return axios.get(url);
  }
  /**
   * @desc : A function to get all applist from sever
   * @returns
   */
  async getAppList() {
    try {
      const data = await axios.post(`${process.env.VUE_APP_API_URL}/get-apps`, {
        org_name: LocalUtils.getEmail().split("@")[1],
      });
      return data.data;
    } catch (error) {
      return { error: true };
    }
  }

  async saveTrend(finalData) {
    const email = LocalUtils.getEmail();
    finalData = { ...finalData, email };
    return (
      await axios.post(
        `${process.env.VUE_APP_API_URL}/credentials/save-trends`,
        {
          ...finalData,
        }
      )
    ).data;
  }

  async getNudgesLog(name) {
    const email = LocalUtils.getEmail();
    return (
      await axios.post(
        `${process.env.VUE_APP_API_URL}/automation/fetch-notification-logs?page=0&limit=50`,
        {
          "user_email": "gaurav@appeq.ai",
          "nudge_name": name

        }
      )
    ).data;
  }

  async getDataObjectLogs(bObj, pageNo, pageSize) {
    const email = LocalUtils.getEmail();
    return (
      await axios.post(`${process.env.VUE_APP_API_URL}/automation/fetch-dataobjects-logs?page=${pageNo}&limit=${pageSize}`, {
        email: email,
        business_object_name: bObj
      })
    )
  }

  async uploadExcelData(sheet, file, appeq_supported_application, business_object_name) {
    try {
      let email = LocalUtils.getEmail();
      let sheets = JSON.stringify(sheet);
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/cron/upload-excel`, {
          sheets, email, file, appeq_supported_application, business_object_name
        }, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      )
    } catch (error) {

    }
  }

  async getExcelSheetNames(file) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/cron/get-excel-sheetnames`, {
          file
        }, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      )
    } catch (error) {

    }
  }

  async connectFreshsales(api_key, business_object_names, base_url) {
    try {
      let user_email = LocalUtils.getEmail();
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/freshworks/authenticate-and-save`, {
          api_key, user_email, business_object_names, base_url
        })
      )
    } catch (error) {

    }
  }

  async doNotShowAgainWidget(id) {
    try {
      const email = localStorage.getItem("__email__id")
      console.log(email);

      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/widget/donotshowagain-widget`, {
          widget_id: id,
          email: email
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  async saveMapping(finalData) {
    const email = LocalUtils.getEmail();
    finalData = { ...finalData, email };
    return (
      await axios.post(
        `${process.env.VUE_APP_API_URL}/credentials/save-mapping`,
        {
          ...finalData,
        }
      )
    ).data;
  }

  async getConfiguratorKeys(business_object_name) {
    try {

      console.log(business_object_name, "asdsdaas")
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/configurator/keys-admin`,
        {
          email: LocalUtils.getEmail(),
          business_object_name: business_object_name,
        }
      );
      return data;
    } catch (error) {
      console.log(error)
    }
  }

  async  getDashboardNum(email){
    try {
    return (await axios.post(`${process.env.VUE_APP_API_URL}/form/dashboardService/get-dashboard-details`, {
      email: email
    })).data
    
      
  } catch (error) {
        return {code:500}
  }
  }

  async getConfiguratorKeysNew(business_object_name) {
    try {

      console.log(business_object_name, "asdsdaas")
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/configurator/keys-admin`,
        {
          email: localStorage.getItem("__email__id"),
          business_object_name: business_object_name,
        }
      );
      return data;
    } catch (error) {
      console.log(error)
    }
  }



  async getFieldsApi(business_object_name) {
    try {

      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/automation/get-fields`,
        {
          email: LocalUtils.getEmail(),
          business_object_name: business_object_name,
        }
      );
      return data;
    } catch (error) {
      console.log(error)
    }
  }
  async saveFieldsApi(finalData) {
    try {
      const email = LocalUtils.getEmail();
      finalData = { ...finalData, email };
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/automation/get-fields`,
        {
          ...finalData
        }
      );
      return data;
    } catch (error) {
      console.log(error)
    }
  }

  async saveColumns(query) {
    const my_email_id = LocalUtils.getEmail();

    query = { ...query, email: my_email_id };
    return (
      await axios.post(
        `${process.env.VUE_APP_API_URL}/save-columns-data`,
        query
      )
    ).data;
  }
  async getColumns() {
    const my_email_id = LocalUtils.getEmail();

    const query = { email: my_email_id };
    return (
      await axios.post(`${process.env.VUE_APP_API_URL}/get-column-data`, query)
    ).data;
  }
  // async getGoogleSheetKeys(sheet_url) {
  //   try {
  //     const data = await axios.post(
  //       `${process.env.VUE_APP_API_URL}/google-sheet/keys`,
  //       {
  //         email: LocalUtils.getEmail(),
  //         sheet_url: sheet_url,
  //       }
  //     );
  //     return data;
  //   } catch (error) { }
  // }
  async saveConfigurator(body) {
    try {
      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/configurator/save`,
        body
      );
      return data.data;
    } catch (error) { }
  }

  async getUserSemnets() {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/roles/get-segments`, {
          email: LocalUtils.getEmail(),
        })
      ).data;
    } catch (error) { }
  }

  async saveSalesforceData(body) {
    try {
      return await axios.post(
        `${process.env.VUE_APP_API_URL}/salesforce/auth`,
        body
      );
    } catch (error) { }
  }

  async onResync(email, bObject) {
    try {
      return await axios.post(
        `${'https://websocket.appeq.ai'}/resync`,
        { email: email, business_object_name: bObject }
      );
    } catch (error) { }
  }

  async newReSync(email, obj) {
    try {
      return await axios.post(`${process.env.VUE_APP_API_URL}/cron/resync`, {
        email: email,
        "business_object_name": obj,
      })
    } catch (error) {

    }
  }


  async updateCreds( obj,feeds) {
    try {
      const email=LocalUtils.getEmail()
      return await axios.post(`${process.env.VUE_APP_API_URL}/credentials/resync`, {
        email: email,
        "business_object_name": obj,
        showInFeeds:feeds
      })
    } catch (error) {

    }
  }


  async saveHubspotData(data) {
    try {
      return await axios.post(
        `${process.env.VUE_APP_API_URL}/hubspot/save`,
        data
      );
    } catch (error) { }
  }

  async downloadPdf(id,params) {
    return await axios.post("https://api1.appeq.ai/cron/export-to-pdf", {
        id: id,
        params: params?params:{},
        email: localStorage.getItem("__email__id")
      }, {
        responseType: 'blob'  // This tells axios to handle the response as binary data (Blob)
      });
  }
  
  async saveZohoData(data) {
    try {
      console.log(data, 'server side')
      return await axios.post(`${process.env.VUE_APP_API_URL}/zoho/save`,
        data
      )
    } catch (error) {

    }
  }

  async getSummaryJson() {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/summary/json`, {
          email: LocalUtils.getEmail(),
        })
      ).data;
    } catch (error) { }
  }

  async queryAllData(query) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/summary/query`, {
          email: LocalUtils.getEmail(),
          query: query,
        })
      ).data;
    } catch (error) { }
  }


  async mixpanelSaveData(data) {
    return (await axios.post(`${process.env.VUE_APP_API_URL}/mixpanel/save`, { ...data, email: LocalUtils.getEmail() })).data
  }


  async authCrm(data) {
    return (await axios.post(`${process.env.VUE_APP_API_URL}/dynamics/get-accesstoken-and-authenticate`, { ...data, business_object_name: "accounts", email: LocalUtils.getEmail() })).data
  }

  async saveCrm(data, business_object_name) {
    return (await axios.post(`${process.env.VUE_APP_API_URL}/dynamics/save`, { ...data, business_object_name: business_object_name, email: LocalUtils.getEmail() })).data
  }

  async resetSource(
    action,
    business_object_name,
    appeq_supported_application, data
  ) {
    let email = LocalUtils.getEmail();
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/action`, {
          action: action,
          business_object_name: business_object_name,
          appeq_supported_application: appeq_supported_application,
          email: email,
          ...data
        })
      ).data;
    } catch (error) { }
  }
  async getAllUsers() {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/get_users_by_organization?token=app123eq123`,
          { email: LocalUtils.getEmail() }
        )
      ).data;
    } catch (error) { }
  }

  async getAllUsers2() {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/get_users_by_organization?token=app123eq123`,
          { email: localStorage.getItem("__email__id") }
        )
      ).data;
    } catch (error) { }
  }

  async getAllManager() {
    try {
      const email = LocalUtils.getEmail();
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/get-team-users`, {
          email_id: email,
        })
      ).data;
    } catch (error) { }
  }

  async getTemplates() {
    return (await axios.post(`${process.env.VUE_APP_API_URL}/template-configuration`, {})).data;

  }

  /**
   * @desc : Get API Ingestion token
   * @param {*} emails
   * @returns
   */
  async getTokenForIngestion(bobject) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/ingest-data/auth`, {
          email: LocalUtils.getEmail(),
          business_object_name: bobject,
        })
      ).data;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * @desc : Save csm data from choosen users
   * @param {*} emails
   * @returns
   */
  async saveCsmData(emails, account_domain) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/csm/save`, {
          email: LocalUtils.getEmail(),
          emails: emails,
          account_domain: account_domain,
        })
      ).data;
    } catch (error) { }
  }

  async sendDashboardInEmail(email, dashNum, params) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/cron/export-to-pdf`, {
          email: email,
          id: dashNum,
          params
        })
      )
    } catch (error) {
    }
  }

  async getGPTInsightsAboutDashboard(id, email, mode) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/cron/dashbord-genie`, {
          id, email, mode
        })
      )
    } catch (error) {

    }
  }

  async insertRedCond(data, bobject) {
    try {
      return await axios.post(
        `${process.env.VUE_APP_API_URL}/master/red-condition`,
        {
          email: LocalUtils.getEmail(),
          business_object_name: bobject,
          red_condition: data,
        }
      );
    } catch (error) { }
  }
  async getConfiguratorKeysForCustomers(business_object_name, email) {
    try {

      const data = await axios.post(
        `${process.env.VUE_APP_API_URL}/configurator/keys-admin`,
        {
          email: email,
          business_object_name: business_object_name,
        }
      );
      return data;
    } catch (error) { }
  }
  async getValue(key, business_object_name) {
    try {
      //const my_email_id = localStorage.getItem("__email__id");
      const my_email_id = LocalUtils.getEmail();
      const data = (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/configurator/get-val`,
          {
            email: my_email_id,
            business_object_name: business_object_name,
            key: key,
          }
        )
      ).data;
      return data;
    } catch (error) {
      return error;
    }
  }

  async getCustomFields() {
    try {
      return await axios.post(
        `${process.env.VUE_APP_API_URL}/custom-fields/get`,
        {
          email: LocalUtils.getEmail(),
        }
      );
    } catch (error) { }
  }

  async saveCustomFields(data) {
    try {
      return await axios.post(
        `${process.env.VUE_APP_API_URL}/custom-fields/save`,
        {
          email: LocalUtils.getEmail(),
          business_object_name: bObject,
          custom_fields: custom_fields,
        }
      );
    } catch (error) { }
  }

  async saveCustomFieldsInfo(data) {
    try {
      return (await axios.post(
        `${process.env.VUE_APP_API_URL}/automation/save-custom-account-fields`,
        {
          email: LocalUtils.getEmail(),
          ...data
        }
      )).data;
    } catch (error) { }
  }

  async getSupportedApps() {
    const my_email_id = LocalUtils.getEmail();
    let orgName = my_email_id.split("@")[1];
    return await axios.get(
      // eslint-disable-next-line no-undef
      `${process.env.VUE_APP_API_URL}/allowed-apps?org_name=${orgName}&from_ext=true`,
      {}
    );
  }

  async getSupportedApps2() {
    const my_email_id = localStorage.getItem("__email__id");
    let orgName = my_email_id.split("@")[1];
    return await axios.get(
      // eslint-disable-next-line no-undef
      `${process.env.VUE_APP_API_URL}/allowed-apps?org_name=${orgName}&from_ext=true`,
      {}
    );
  }

  async getAutoMations(data) {
    try {
      return ((await axios.post(`${process.env.VUE_APP_API_URL}/automation/get-automation`, data)))
    } catch (e) {
      console.log(e)
    }
  }

  async getCustomAccountfields(bobj) {
    try {
      return await axios.post(
        `${process.env.VUE_APP_API_URL}/automation/get-custom-account-fields`,
        {
          email: LocalUtils.getEmail(),
          business_object_name: bobj
        }
      );
    } catch (error) { }
  }

  async addWidget(data) {
    try {
      let email = LocalUtils.getEmail();
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/widget/add-widget-content`, {
          createdBy: email,
          email: email,
          ...data
        })
      );
    } catch (error) {

    }
  }
  async updateWidget(data) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/widget/add-widget-content`, {
          updatedBy: LocalUtils.getEmail(),
          ...data
        })
      );
    } catch (error) {

    }
  }
  async deleteWidgetContent(data) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/widget/delete-widget-content`, {
          ...data
        })
      );
    } catch (error) {

    }
  }

  async deleteCustomFilds(item) {
    try {
      return await axios.delete(
        `${process.env.VUE_APP_API_URL}/automation/save-custom-account-fields`,
        {
          data: item
        }
      );
    } catch (error) { }
  }

  async credentailsQuery(bObj, application) {
    try {
      const email = LocalUtils.getEmail();
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/credentials/status`, {
          email: email,
          business_object_name: bObj,
          appeq_supported_application: application,
        })
      ).data;
    } catch (error) { }
  }

  async getFunction(buisnessObject) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/get-function`, {
          business_object_name: buisnessObject,
        })
      ).data;
    } catch (error) { }
  }
  async getGraphFunction() {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/get-function`, {
          "type": { "$in": ["graph", "bargraph"] }
        })
      ).data;
    } catch (error) {

    }
  }

  async getAllUsers() {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/userInfo/get-all-users`,
          { email: LocalUtils.getEmail() }
        )
      ).data;
    } catch (error) { }
  }
  async changeUsersRole(e, selected, sanbox) {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/userInfo/update-roles`,
          {
            email_id: e,
            makeAdmin: selected == "System Admin" || selected == 'Manager' ? true : false,
            role: selected,
            parent_email_id: LocalUtils.getEmail(),
            sandbox: sanbox
          }
        )
      ).data;
    } catch (error) { }
  }
  async deleteAppEQAgent(orgId, email) {
    try {
      console.log(orgId, email);
      return (
        await axios.delete(
          `${process.env.VUE_APP_API_URL}/create-appeq-agent`,
          {
            data: {
              org_id: orgId,
              email_id: email
            }
          }
        )
      ).data;
    } catch (error) { }
  }

  async makeManager(e, selected, manager) {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/userInfo/update-roles`,
          {
            email_id: e,
            makeAdmin: selected == "Admin" ? true : false,
            parent_email_id: LocalUtils.getEmail(),

            manager: manager,
          }
        )
      ).data;
    } catch (error) { }
  }

  async saveSegment(seg) {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/master/save-segments`,
          {
            ...seg,
            email: LocalUtils.getEmail(),
          }
        )
      ).data;
    } catch (error) { }
  }

  async forceSaveSeg(seg, mov) {
    try {
      let data = {
        ...seg,
        email: LocalUtils.getEmail(),
      };
      if (mov) {
        data["move"] = "members";
      }
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/master/save-segments-force`,
          data
        )
      ).data;
    } catch (error) { }
  }

  async deleteSegments(seg) {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/master/delete-segments`,
          {
            ...seg,
            email: LocalUtils.getEmail(),
          }
        )
      ).data;
    } catch (error) { }
  }
  async getTasks(filterCond) {
    const my_email_id = localStorage.getItem("__email__id");

    let query = { filterCond, email: my_email_id };

    // if(filterCond){
    //  const filter= filterCond.map(d=>{
    //     return{
    //       [d.filter.key.key]:{[d.filter.operetor.query]:d.filter.value}
    //     }
    //   })
    //   query={...query,filter:{"$and":filter}}
    // }
    return (await axios.post(`${process.env.VUE_APP_API_URL}/return-task`, query)).data;
  }
  async completeTasks(task) {
    const email = localStorage.getItem("__email__id");
    return (
      await axios.post(`${process.env.VUE_APP_API_URL}/tasks/save`, {
        ...task
      })
    ).data;
  }

  // for summary
  async fetchTicketHighLights(domain) {
    return await axios.post(`${process.env.VUE_APP_API_URL}/get-sentiments`, {
      token: "peF2U6pQo8V88xnYskC8j4q6NNBjEEgDy0O4hxOG",
      email: "gaurav@appeq.ai",
      domain: "appeq",
      account_domain: domain,
    });
  }
  async fetchSummaryData(domain, id) {
    const email = localStorage.getItem("__email__id");
    // const chrome_props = (await feeds.getAllProps()).data;
    return (
      await axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.VUE_APP_API_URL}/summary/jsonbyemail`,
        {
          email: email,
          account_domain: domain,
          id: id,
        }
      )
    ).data;
  }

  async fetchSummaryDataValue(domain, id) {
    const email = localStorage.getItem("__email__id");
    // const chrome_props = (await feeds.getAllProps()).data;
    return (
      await axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.VUE_APP_API_URL}/summary/newjsonbyemail`,
        {
          email: email,
          account_domain: domain,
          id: id,
        }
      )
    ).data;
  }

  async fetchSummaryDataObj(domain, bobject, funcname, key) {
    const email = LocalUtils.getEmail();

    return await axios.post(
      `${process.env.VUE_APP_API_URL}/summary/accounts-data`,
      {
        email: email,
        account_domain: domain,
        business_object_name: bobject,
        function: funcname,
        key: key,
      }
    );
  }

  async aggregateData(query) {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/summary/aggregatequery`,
          {
            email: LocalUtils.getEmail(),
            query: query,
          }
        )
      ).data;
    } catch (error) { }
  }

  async saveWidget(query) {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/widget/add-widgets`,
          {
            email: localStorage.getItem("__email__id"),
            ...query
          }
        )
      ).data;
    } catch (error) { }
  }

  async saveWidgetfromsettings(query, email) {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/widget/add-widgets`,
          {
            email: email,
            ...query
          }
        )
      ).data;
    } catch (error) { }
  }
  async deleteWidget(data) {
    try {
      console.log(data, 'code here');
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/widget/delete-widget`,
          {
            ...data
          }
        )
      )
    } catch (error) {

    }
  }
  async getWidgets(query) {
    try {
      return (
        await axios.post(
          `${process.env.VUE_APP_API_URL}/widget/get-widgets`,
          {
            email: LocalUtils.getEmail(),
          }
        )
      ).data;
    } catch (error) { }
  }

  async getWidgetContent(email) {
    try {
      return (
        await axios.post(`${process.env.VUE_APP_API_URL}/widget/get-widget-content`,
          {
            email: email ? email : LocalUtils.getEmail(),
          }
        )
      ).data;
    } catch (error) {

    }
  }

  async getWidgetIcons() {
    try {
      return (
        await axios.get(`${process.env.VUE_APP_API_URL}/widget/get-appeq-icons`)
      ).data;
    } catch (error) {

    }
  }

  async triggerautoNudge(appeq_org_id, idx) {
    try {
      return (
        await axios.get(
          `https://websocket.appeq.ai/trigger-appeq-nudge-by-index/${appeq_org_id}/${idx}`)
      ).data;
    } catch (error) { }
  }

  async cloneDataToOrg() {
    const email = localStorage.getItem("__email__id")
    const data = await axios.post(`https://websocket.appeq.ai/clone-data-to-demoorg`, {
      email: email
    })
    return data.data
  }


  async saveAccountstatus(accountStatus) {
    const email = LocalUtils.getEmail()
    const data = await axios.post(`${process.env.VUE_APP_API_URL}/account-status/save`, {
      ...accountStatus,
      email: email
    })
    return data.data
  }
  async getObjectList(widget) {
    const email = localStorage.getItem("__email__id")
    const data = await axios.post(`${process.env.VUE_APP_API_URL}/widget/get-widget-object-keys`, {
      ...widget,
      email: email
    })
    return data.data
  }

  async saveNotes(data, domain, id, businessObject, appeqkey) {
    try {
      return await axios.post(`${process.env.VUE_APP_API_URL}/notes/insert-notes`, {
        type: businessObject,
        appeqkey: appeqkey,
        email: localStorage.getItem("__email__id"),
        data: data,
        id: id,
        account_domain: domain
      })
    } catch (error) {

    }
  }
  stripHtml(html) {
    // Create a temporary div element
    var tempDiv = document.createElement("div");
    // Set the HTML content to the div
    tempDiv.innerHTML = html;
    // Extract the plain text from the div
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  async getContacts(query, pageNumber) {
    pageNumber = pageNumber ? pageNumber : 1
    return (await axios.post(`${process.env.VUE_APP_API_URL}/automation/get-contacts`, { ...query })).data;

  }


  async saveNewTasks(task) {
    task['user_email'] = localStorage.getItem("__email__id")
    return (await axios.post(`${process.env.VUE_APP_API_URL}/tasks/save-task`, task)).data
  }

  async getSummaryInfo(){
    return (await axios.post(`${process.env.VUE_APP_API_URL}/get-summary-details`,{
      email:localStorage.getItem("__email__id")
    })).data
  }

  async updateUserInfo(query) {
    try {
      const data = await axios.post(`${process.env.VUE_APP_API_URL}/update-user`, query);
      return data;
    } catch (error) {
      return { code: 500, msg: error + "" };
    }
  }

  async sendEmail(subject, emailTo, message, emailFrom, attachments) {
    try {
      let response;
  
        // Create a FormData instance for multipart/form-data if there's an attachment
        const formData = new FormData();
        formData.append('subject', subject);
        formData.append('content', message+"<br/><span style='font-size:8px'>//AI Assisted Email by <a href='https://appeq.ai/'>AppEQ.ai</a></span>");
        formData.append('toAddress', emailTo);
        formData.append('user_email', emailFrom);
        if(attachments)
        attachments.forEach((attachment, index) => {
          formData.append(`file`, attachment, attachment.name || `attachment${index + 1}.pdf`);
        });
        response = await axios.post(
          `${process.env.VUE_APP_API_URL}/automation/send-email`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        );
  
      return response;
    } catch (error) {
      console.error('Error sending email:', error);
      throw error;
    }
  }
  

  async saveTaskToCalendat(summary, desc, start) {
    try {
      const res = await axios.post(`${process.env.VUE_APP_API_URL}/create-calender-task`, {
        summary: summary,
        description: desc,
        start: start,
        end: start,
        email: localStorage.getItem("__email__id")
      })
      if (res.status == 200) {
        return res.data
      } else {
        return {
          code: res.status,
          ...res.data
        }
      }


    } catch (error) {
      console.log(error)
    }
  }


  async checkRefreshToken() {
    try {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/check-authorization`, {
        email: localStorage.getItem("__email__id")
      });

      if (response.status === 200) {
        // Successfully received response
        return { code: 200, success: true, data: response.data };
      } else {
        // Return an error object with response code and message
        return {
          code: response.status,
          success: false,
          responseCode: response.status,
          msg: `Error: Received status code ${response.status}`
        };
      }
    } catch (error) {
      // Return an error object with response code and message
      if (error.response) {
        // Error response from server
        return {
          code: error.response.status,

          success: false,
          responseCode: error.response.status,
          msg: error.response.data.message || 'An error occurred'
        };
      } else if (error.request) {
        // Error with the request
        return {
          code: 400,

          success: false,
          responseCode: 0,
          msg: 'No response received from server'
        };
      } else {
        // Other errors
        return {
          success: false,
          code: 400,
          responseCode: 0,
          msg: error.message || 'An unexpected error occurred'
        };
      }
    }
  }


  async saveAuth(data) {
    return (await axios.post(`${process.env.VUE_APP_API_URL}/store-authorization`, {
      email: localStorage.getItem("__email__id"),
      ...data
    })).data
  }




  async resyNotes(crmBObject, notes, accId) {
    notes = this.stripHtml(notes)
    return (await axios.post(`${process.env.VUE_APP_API_URL}/notes/sync-notes`, {
      user_email: localStorage.getItem("__email__id"),
      type: crmBObject,
      notes: notes,
      account_id: accId
    })).data
  }
  async saveTasks(query) {
    const my_email_id = localStorage.getItem("__email__id");

    query = {
      email: my_email_id, ...query,
      appeq_supported_application: "appeq",
      business_object_name: "tasks",


    };
    return (await axios.post(`${process.env.VUE_APP_API_URL}/tasks/save`, query)).data;
  }

  async saveAudit(data, domain) {
    try {
      return await axios.post(`${process.env.VUE_APP_API_URL}/audit/insert-audit`, {
        type: "Notes",
        email: localStorage.getItem("__email__id"),
        data: data,
        account_domain: domain,
      })
    } catch (error) {

    }
  }

  async getNotes(domain, state, id) {
    try {
      return await axios.post(`${process.env.VUE_APP_API_URL}/notes/get-notes`, {
        type: state,
        email: localStorage.getItem("__email__id"),
        account_domain: domain,
        id: id
      })
    } catch (error) {

    }
  }
  async saveTags(query, tag) {
    try {
      return (await axios.post(`${process.env.VUE_APP_API_URL}/tags/save-tags`, {
        email: localStorage.getItem("__email__id"),
        set: {
          appeq_tag: tag
        },
        filter: {
          query
        }
      })).data
    } catch (error) {

    }
  }

  async gettags(query) {
    try {
      return (await axios.post(`${process.env.VUE_APP_API_URL}/tags/get-tags`, {
        email: localStorage.getItem("__email__id"),
        business_object_name: query
      })).data
    } catch (error) {

    }
  }

   blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.onload = function(event) {
            // The result is a Base64 encoded string
            const base64String = event.target.result.split(',')[1]; // Get the Base64 part
            resolve(base64String);
        };

        reader.onerror = function(event) {
            reject(new Error("Failed to read Blob"));
        };

        // Read the Blob as a Data URL (Base64)
        reader.readAsDataURL(blob);
    });
}   
}
export default new MyServer();
