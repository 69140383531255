import { render, staticRenderFns } from "./AllDashboards.vue?vue&type=template&id=4c6578fc&scoped=true"
import script from "./AllDashboards.vue?vue&type=script&lang=js"
export * from "./AllDashboards.vue?vue&type=script&lang=js"
import style0 from "./AllDashboards.vue?vue&type=style&index=0&id=4c6578fc&prod&lang=scss&scoped=true"
import style1 from "./AllDashboards.vue?vue&type=style&index=1&id=4c6578fc&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c6578fc",
  null
  
)

export default component.exports