import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";
import Login from "@/pages/Login.vue";
import MyOrg from "@/pages/MyOrganization.vue";
import Configure from "@/pages/Configure.vue";
import AppeqCustomers from "@/pages/AppeqCustomers"
import HelpCenter from "@/pages/HelpCenter.vue";
import EmailAssists from "../components/EmailAssists.vue"
import InternalLogin from "@/pages/InternalLogin.vue";
import MainOnboardingVue from "@/pages/Onboarding/MainOnboarding.vue";
import Onboarding from "@/pages/Onboading.vue";
import PopupDemo from "@/pages/PopupDemo.vue";
import FlowChart from "@/pages/FlowChartDemo.vue";


import NotificationConfigure from "@/pages/NotificationConfigure.vue";
import FeedsConfiguration from "@/pages/Configuration/FeedsConfiguration.vue";
import PopupIframe from "../pages/PopupIframe.vue";
import PrePopupIframe from "../pages/PrePopupIframe.vue";
import IntercomForFeeds from "../pages/IntercomForFeeds.vue";
import WidgetSettings from "../pages/WidgetSettings.vue";
import AllDashboards from "../pages/AllDashboards.vue";
import CustomerDashboard from "../pages/CustomerDashboard.vue";
import PulseModal from "../pages/PulseModal.vue";
import Notes from "../pages/Notes.vue";

const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const TableList = () => import("@/pages/TableList.vue");
const PlayBook = () => import("@/pages/PlayBook.vue");
const GrowthHub = () => import("@/pages/GrowthHub.vue");

const integrations = () => import("@/pages/Integrations.vue");
const red = () => import("@/pages/red-condition.vue");
const Config = () => import("@/pages/Configuration/Index.vue");
const Manager = () => import("@/pages/ManagerOrg.vue");
const MetabaseDashboard = () => import("@/pages/SummaryView.vue");
const Widgets = () => import("@/pages/WidgetsTable.vue");
const WidgetsTest = () => import("@/components/WidgetTest.vue");
const TreeMapper = () => import("@/pages/TreeMapper.vue")

const AllowedApps = () => import("@/pages/New-Allowed-Apps-View.vue");
const WidgetType = () => import("@/pages/WidgetType.vue");
// const FeedsConfiguration = () =>
//   import("@/pages/Configuration/FeedsConfiguration.vue");

const TemplateDesigner = () =>
  import("@/pages/Configuration/TemplateDesigner.vue");

const functionScreen = () => import("@/pages/functionScreen.vue");
const SummuryCreator = () => import("@/pages/Configuration/SummuryCreator.vue");
const NewSummary = () => import("@/pages/Configuration/newSummaryView.vue");
const SideDash = () => import("@/pages/SideDash.vue")
const Plans = () => import("../components/Plans.vue");
const NewDashboard = () => import("@/pages/NewDashboard.vue");
const UserManagment = () => import("@/pages/UserManagment.vue");
const Department = () => import("@/pages/Department.vue");
const Subscription = () => import("../components/Subscription.vue");

function getUser() {
  const adminCred = localStorage.getItem("ADMIN_CREDS");
  if (adminCred) {
    const data = JSON.parse(adminCred);
    if (data && data.__email__id) {
      return data.__email__id;
    }
  }
}
function getAdminStatus() {
  const admin = localStorage.getItem('IS_ADMIN');
  return admin;
}

const routes = [
  { path: "/", component: Login },
  { path: "/dashboard", component: MetabaseDashboard },
  { path: "/sidedash", component: SideDash },
  { path: "/all-dashboards", component: AllDashboards },
  { path: "/customer", component: CustomerDashboard },

  { path: "/popup", component: PopupIframe },
  { path: "/pre-popup", component: PrePopupIframe },
  { path: "/intercom-feeds", component: IntercomForFeeds },
  { path: "/onboarding", component: Onboarding },
  { path: "/popup-demo", component: PopupDemo },
  { path: "/flowchart", component: FlowChart },
  { path: "/pulse-modal", component: PulseModal },


  { path: "/widget-settings", component: WidgetSettings },
  { path: "/tree", component: TreeMapper },
  { path: "/notes", component: Notes },

  
  { path: "/internal-login", component: InternalLogin },
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "business-object",
        name: "Data Source",
        component: TableList,
      },
      {
        path: "growth-hub",
        name: "Growth Hub",
        component: GrowthHub,
      },
      {
        path: "launchers",
        name: "Widgets",
        component: Widgets,
      },
      {
        path: "widgetstest",
        name: "Widgets",
        component: WidgetsTest,
      },
      {
        path: "email-assists",
        name: "Email Assists",
        component: EmailAssists,
      },
      {
        path: "task-playbook",
        name: "Playbook",
        component: PlayBook,
      },
      {
        path: "integrations",
        name: "Integrations",
        component: integrations
      },
      {
        path: "my-team",
        name: "My Team",
        component: Manager,
      },
      {
        path: "summary",
        name: "Widget",
        component: Config,
      },
      {
        path: "executive-dashboard",
        name: "Feeds Widget Configurator",
        component: FeedsConfiguration,
      },
      {
        path: "plans",
        name: "Plans",
        component: Plans,
      },
      {
        path: "subscription",
        name: "Subscription",
        component: Subscription,
      },
      {
        path: "department",
        name: "Profiles",
        component: Department,
      },
      {
        path: "notifications",
        name: "Nudge Settings",
        component: NotificationConfigure,
      },
      {
        path: "support-center",
        name: "Support Center",
        component: HelpCenter
      },
      {
        path: "my-customers",
        name: "My Organization",
        component: MyOrg,
      },
      {
        path: "configure",
        name: "Configure",
        component: Configure
      },
      {
        path: "appeq-customers",
        name: "Appeq Customers",
        component: AppeqCustomers
      },

      {
        path: "user-managment",
        name: "User Managment",
        component: UserManagment,
      },

      {
        path: "allowed-apps",
        name: "launchpad",
        component: AllowedApps,
      },
      {
        path: "contents",
        name: "Widget Type",
        component: WidgetType
      },
      {
        path: "template-designer",
        name: "Configure your Summary Widget",
        component: TemplateDesigner,
      },
      {
        path: "summary-creator",
        name: "Create Summary Widget",
        component: NewSummary,
      },
      {
        path: "functions",
        name: "Create Summary Widget",
        component: functionScreen,
      },
      {
        path: "test",
        name: "Create Summary Widget",
        component: NewSummary,
      },
      {
        path: "early-warning",
        name: "Configure your red condition",
        component: red,
      },
    ],
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
